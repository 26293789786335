.career-card {
  width: 320px;
  height: 300px;
  flex-shrink: 0;

  position: relative;
  overflow: hidden;
  .career-img {
    img {
      width: 100%;
    }
  }
  .career-content {
    background-color: #fff;
    height: 144px;
    width: 100%;
    h3 {
      color: #333;
      // font-family: Poppins;
      font-size: 18px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }
    p {
      color: #828282;
      // font-family: Poppins;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
    ul li {
      color: #fff;
    }
    button {
      color: #113cc0;
      //   font-family: Poppins;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      svg {
        display: inline-block;
        margin-left: 6px;
      }
    }
  }
}
.career-card:hover .career-content {
  bottom: 0;
  transition: 0.5s ease-in-out;
  transform: scale(1);
}
.list-items ul li {
  list-style-type: disc;
  text-decoration: none;
  &:hover {
    a {
      font-weight: 600;
      color: #00c798;
    }
  }
}

.career-button-section {
  button {
    display: flex;
    padding: 11px 16px;
    justify-content: center;
    width: auto;
    box-sizing: border-box;
    align-items: center;
    gap: 8px;
    font-size: 12px;
    border-radius: 50px;

    svg {
      display: inline-block;
    }
  }
}

.list-items ul li {
  list-style-type: none; /* Remove default list style */
  position: relative;
  padding-left: 30px; /* Add padding to accommodate the hyphen */
  white-space: nowrap; /* Prevent wrapping */
}

.list-items ul li::before {
  content: "";
  position: absolute;
  left: 0; /* Align to the right */
  top: 50%; /* Center vertically */
  transform: translateY(-50%) scaleX(1); /* Center vertically and set initial scale */
  width: 18px; /* Length of the hyphen */
  height: 4px; /* Thickness of the hyphen */
  background-color: #00c798; /* Adjust hyphen color */
  transition: transform 0.3s ease; /* Transition for width change on hover */
  transform-origin: right; /* Transition from left */
}

.list-items ul li:hover::before {
  transform: translateY(-50%) scaleX(2); /* Expand width on hover from right to left */
}

.list-items ul li a {
  text-decoration: none;
  color: inherit; /* Inherit text color */
  transition: color 0.3s ease;
}

.list-items ul li:hover a {
  font-weight: 600;
  color: #00c798; /* Change text color on hover */
}
