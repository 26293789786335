.image-card-main {
  padding: 16px;
  flex-direction: column;
  align-items: center;
  gap: 14px;
  @media (max-width: 640px) {
    padding: 8px;
  }
  .city-btn-section {
    margin-top: 14px;
  }
}

.image-card-main:hover {
  border-radius: 16px;
  background: #fff;
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.16);
}
