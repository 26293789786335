.social-media-icons {
  transition: border-radius 0.3s, background-color 0.3s, transform 0.2s;
}

:root {
  --cor-body-bg: #ffffff;
  --cor-btn-bg: #f1f1f1;
  --cor-btn-shadow: #00000070;
  --cor-btn-bg-hover: var(--cor-icone);
  --cor-icone: #3498db;
  --cor-icone-hover: var(--cor-btn-bg);
}

body {
  padding: 0;
  margin: 0;
  background: var(--cor-body-bg);
}

.social ul {
  display: flex;
}

.social ul li {
  list-style: none;
  margin: 0 2px;
}

.social ul li a {
  position: relative;
  display: block;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  /* Ensure the overflow is hidden to contain the transition effect */
  transition: background 1s ease, box-shadow 1s ease;
  /* Slow down the transition */
  /* Smooth transition for background color and box-shadow */

  svg {
    width: 25px;
    height: 25px;
    transition: filter 1s ease;
    /* Slow down the transition */
    /* Smooth transition for filter property */
    fill: #fff;
    /* Set initial color to white */
  }

  &:hover {
    background: #fff;
    /* Change background color on hover */
    box-shadow: 0 0 5px #0307e4;

    .icon-color {
      filter: brightness(200%) invert(100%);
      /* Convert white to blue on hover using filter */
    }
  }

  &:before {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 0%;
    height: 0%;
    background-color: #ffffff;
    /* Change the color to match your background */
    border-radius: 50%;
    transition: width 0.5s ease, height 0.5s ease;
    /* Reduce transition duration for quicker effect */
  }

  &:hover:before {
    width: 100%;
    /* Expand the width on hover */
    height: 100%;
    /* Show the background overlay on hover */
  }
}

@keyframes aaa {
  0% {
    left: -110%;
    top: 90%;
  }

  50% {
    left: 10%;
    top: -30%;
  }

  100% {
    top: -10%;
    left: -10%;
  }
}
