.prepare-exam-title {
  color: #383838;
  font-family: Roboto;

  font-style: normal;
  font-weight: 500;
}
.prepare-exam-description {
  color: #6b7280;
  text-align: center;
  font-family: "Open Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
}
