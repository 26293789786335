.application-admission::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-color: white;
  left: 0;
  top: 0;
  opacity: 0.8;
}
