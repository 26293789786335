.swiper-slide {
  height: auto;
}
.ExploreMore-occupation .nextButton svg path,
.ExploreMore-occupation .prevButton svg path {
  color: black;
}

.ExploreMore-occupation .progressbarBg .bg-blue {
  background: #00cc99;
}
